<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="4">
        <Select
          v-model="assetId"
          size="small"
          placeholder="资产"
          @on-change="handleChangeAsset"
        >
          <Option :value="-1">全部资产</Option>
          <Option
            v-for="item in companyAssetArray"
            :key="'asset_' + item.id"
            :value="item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.stationId"
          size="small"
          placeholder="站点"
          :clearable="true"
          :disabled="assetId===-1"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_' + item.id"
            :value="item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.position"
          size="small"
          placeholder="位置"
          :clearable="true"
        >
          <Option
            v-for="item in positionArray"
            :key="'position_' + item.id"
            :value="item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.deviceModel"
          size="small"
          :clearable="true"
          placeholder="设备类型"
        >
          <Option
            v-for="item in deviceTypeArray"
            :value="item.id"
            :key="'deviceType' + item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col span="6">
        <Input
          size="small"
          v-model="query.keyword"
          placeholder="资源编号、客户名称、品牌名称、画面名称等"
        />
      </i-col>
    </Row>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="6">
        <span>距离档期结束还有 </span>
        <InputNumber
          size="small"
          :min="0"
          v-model="query.beforeScheduleEnd"
        ></InputNumber>
        <span> 天</span>
      </i-col>
      <i-col span="6">
        <span>档期结束后 </span>
        <InputNumber
          size="small"
          :min="0"
          v-model="query.afterScheduleEnd"
        ></InputNumber>
        <span> 天内无上刊任务</span>
      </i-col>
      <i-col span="4">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          class="m-r-5"
          @click="handleSearch"
        >搜索</Button>
        <Button
          type="success"
          size="small"
          :disabled="selectedItemArray.length===0"
          :loading="addTaskLoading"
          @click="onAdd"
        >确认添加</Button>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        max-height="620"
        size="small"
        :data="dataList"
        :columns="tableColumns"
        :loading="dataLoading"
        @on-selection-change="handleSelection"
      ></Table>
      <Row :gutter="8">
        <i-col span="12">
          <div
            class="m-t-5"
            style="line-height: 25px"
          >
            当前已选中<span style="color: #ff9900; padding: 0 3px">{{
              selectedItemArray.length
            }}</span>条
          </div>
        </i-col>
        <i-col span="12">
          <div class="paging_style">
            <Page
              size="small"
              :total="total"
              :page-size="query.pageSize"
              show-total
              show-elevator
              show-sizer
              :page-size-opts="pagesizeSetting"
              @on-page-size-change="changePageSize"
              :current="query.pageNumber"
              @on-change="changePage"
            ></Page>
          </div>
        </i-col>
      </Row>
    </div>
    <device-work-record ref="deviceWorkRecord"></device-work-record>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >{{ fileName }}</p>
      <img-light-box ref="imgLightBox"></img-light-box>
    </Modal>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { companyMixin } from '@/assets/mixins/company'
import { commonMixins } from '@/assets/mixins/common'
import { addTaskMixins } from '../mixins/addTask'
import { GetCurrentSchedule } from '@/utils/dateFormat'

import { getPublishingPage, createTaskitem } from '@/api/msp/uninstall'

import ImgLightBox from '@/components/common/ImgLightBox'
import DeviceWorkRecord from './DeviceWorkRecord'
export default {
  mixins: [sysMixins, commonMixins, companyMixin, addTaskMixins],
  components: {
    ImgLightBox,
    DeviceWorkRecord
  },
  data () {
    return {
      query: {
        pageSize: 15,
        pageNumber: 1,
        assetId: null,
        stationId: null,
        position: null,
        deviceModel: null,
        afterScheduleEnd: null,
        beforeScheduleEnd: null,
        taskId: null,
        keyword: ''
      },
      assetId: null,
      companyId: this.$store.getters.token.userInfo.companyId,
      dataLoading: false,
      dataList: [],
      total: 0,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '编号', align: 'left', key: 'code' },
        // { title: '线路', align: 'left', key: 'code' },
        { title: '站点', align: 'left', key: 'stationName' },
        { title: '设备类型', align: 'left', key: 'deviceModelName' },
        {
          title: '客户名称',
          align: 'left',
          tooltip: true,
          key: 'advertiserName'
        },
        {
          title: '当前画面',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (params.row.fileKeyList && params.row.fileKeyList.length > 0) {
              return h('div', [
                h('img', {
                  domProps: {
                    src:
                      params.row.fileKeyList[0] +
                      '?x-oss-process=image/resize,w_64'
                  },
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.fileKeyList
                      }
                      this.fileName =
                        params.row.stationName + '-' + params.row.code
                      this.$refs.imgLightBox.init(postData)
                      this.visiblePreview = true
                    }
                  },
                  style: {
                    width: '64px',
                    cursor: 'pointer'
                  }
                })
              ])
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        { title: '画面数', minWidth: 60, align: 'center', key: 'side' },
        { title: '画面名称', align: 'left', tooltip: true, key: 'fileName' },
        {
          title: '履约档期(距离档期结束)',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h(
              'span',
              GetCurrentSchedule(
                params.row.startDate,
                params.row.endDate,
                false
              ) +
              (params.row.beforeEndDays > 0
                ? '(' + params.row.beforeEndDays + '天)'
                : '')
            )
          }
        },
        {
          title: '已结束(天)',
          align: 'center',
          key: 'afterEndDays',
          render: (h, params) => {
            return h(
              'span',
              {
                style: { color: params.row.afterEndDays > 0 ? 'red' : '' }
              },
              params.row.afterEndDays > 0 ? params.row.afterEndDays : '-'
            )
          }
        },
        {
          title: '下次履约开始时间',
          minWidth: 100,
          align: 'center',
          key: 'nextActionDate'
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h(
              'a',
              {
                on: {
                  click: () => {
                    this.onViewWorkRecord(params.row)
                  }
                }
              },
              '历史作业记录'
            )
          }
        }
      ],
      pagesizeSetting: [15, 50, 100],
      // selectedItemArray: [],
      tempSelectedItems: [],
      fileName: '',
      visiblePreview: false,
      addTaskLoading: false
    }
  },
  computed: {
    curTaskId () {
      return this.$store.state.task.curTaskId
    },
    selectedItemArray: {
      get () {
        return this.$store.state.task.selectedTaskitemIdArray
      },
      set (val) {
        this.$store.commit('set_selected_taskitemid_array', val)
      }
    }
  },
  created () {
    this.query.taskId = this.curTaskId
    this.getCompanyAssetArray()
  },
  mounted () {
    this.setLinkTagArray({
      key: 'AddIssueTask',
      value: '添加下刊申请任务',
      actived: true
    })
    this.setActivedTagName('AddIssueTask')
    this.setLinkRouterMaps([
      'AddIssueTask',
      () => {
        this.handlePage()
      }
    ])
  },
  methods: {
    getTableData () {
      this.dataLoading = true
      getPublishingPage(this.query)
        .then((res) => {
          if (res && !res.errcode) {
            this.dataList = res.list
            if (this.dataList.length) {
              this.dataList.forEach((item) => {
                if (this.selectedItemArray.some(x => x === item.taskitemId)) {
                  item._checked = true
                }
              })
            }
            this.total = res.totalRow
            this.query.pageNumber = res.pageNumber
          } else {
            this.dataList = []
            this.total = 0
            this.query.pageNumber = 1
          }
        })
        .finally(() => {
          this.dataLoading = false
        })
    },
    handleChangeAsset () {
      this.query.assetId = this.assetId === -1 ? undefined : this.assetId
      this.query.pageNumber = 1
      this.query.stationId = null
      this.query.position = null
      this.query.devicemodel = null
      this.query.keyword = ''
      this.selectedItemArray = []
      this.tempSelectedItems = []

      this.getStationData()
      this.getPositionData()
      this.getResourceTypeData()
    },
    handleSearch () {
      this.query.assetId = this.assetId === -1 ? undefined : this.assetId
      this.query.pageNumber = 1
      this.selectedItemArray = []
      this.tempSelectedItems = []
      this.getTableData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.getTableData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.selectedItemArray = []
      this.tempSelectedItems = []
      this.getTableData()
    },
    handleSelection (selection) {
      const taskitemIds = selection.map((x) => x.taskitemId)
      this.handelPageSelections(
        this.tempSelectedItems,
        taskitemIds,
        this.query.pageNumber
      )
      // 最终选中的所有taskItemIds
      this.selectedItemArray = []
      this.tempSelectedItems.forEach((item) => {
        this.selectedItemArray = this.selectedItemArray.concat(
          item.existSelections
        )
      })
    },
    /**
     * 查看资源历史作业记录
     */
    onViewWorkRecord (param) {
      this.$refs.deviceWorkRecord.show(param.deviceId)
    },
    onAdd () {
      if (this.selectedItemArray.length === 0) {
        this.$Notice.warning({ desc: '当前没有选择任何项，无法进行操作' })
        return false
      }
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定添加已选择项？',
        onOk: () => {
          this.addTaskLoading = true
          createTaskitem({ taskId: this.curTaskId, taskitemIds: JSON.stringify(this.selectedItemArray) }).then(res => {
            if (res && res.errcode === 0) {
              this.selectedItemArray = []
              this.tempSelectedItems = []
              this.$Notice.success({ desc: '操作成功' })
              this.getTableData()
            }
          }).finally(() => { this.addTaskLoading = false }).catch(() => { this.addTaskLoading = false })
        }
      })
    },
    handlePage () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLeftComponent('')
      this.setRightComponent('')
      this.setBottomComponent('AddIssueTask')
    }
  },
  watch: {
    companyAssetArray: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.length) {
          this.assetId = val[0].id
          this.query.assetId = this.assetId === -1 ? undefined : this.assetId
          this.getStationData()
          this.getPositionData()
          this.getResourceTypeData()

          this.getTableData()
        }
      }
    }
  }
}
</script>
