import { getRimStationByAssetIds } from '@/api/rim/station'
import { getPositionQuantity, getDeviceModelQuantity } from '@/api/rim/device'

export const addTaskMixins = {
  data () {
    return {
      stationArray: [],
      positionArray: [],
      deviceTypeArray: []
    }
  },
  methods: {
    getStationData () {
      getRimStationByAssetIds({ assetIds: this.query.assetId ? JSON.stringify([this.query.assetId]) : '' }).then(res => {
        if (res && !res.errcode) {
          this.stationArray = res
        } else {
          this.stationArray = []
        }
      })
    },
    getPositionData () {
      // const assetIds = []
      // assetIds.push(this.query.assetId)
      // const data = {
      //   assetIds: JSON.stringify(assetIds)
      // }
      getPositionQuantity({ assetId: this.query.assetId }).then(res => {
        if (res && !res.errcode) {
          this.positionArray = res
        } else {
          this.positionArray = []
        }
      })
    },
    getResourceTypeData () {
      getDeviceModelQuantity({ assetId: this.query.assetId }).then(res => {
        if (res && !res.errcode) {
          this.deviceTypeArray = res
        } else {
          this.deviceTypeArray = []
        }
      })
    }
  }
}
